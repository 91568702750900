<template>
    <div class="mod-config">
        <el-form :inline="true" :model="dataForm" @keyup.enter.native="search()">
            <table class="detailTable_search" style="width: 100%">
                <tr>
                    <td class="titleTd width80px">{{ $t('rules.ruleName') }}</td>
                    <td class="valueTd">
                        <el-input v-model="dataForm.roleName" :placeholder="$t('rules.ruleName')" clearable></el-input>
                    </td>
                </tr>
            </table>
        </el-form>
        <div class="wxts_msg_search">
            <div class="f_t">
                <el-button-group>
                    <el-button size="mini" type="success" @click="search()" :disabled="dataListLoading">{{ $t('query') }}</el-button>
                    <el-button size="mini" v-if="isAuth('sys:role:save')" type="primary" @click="addOrUpdateHandle()" :disabled="dataListLoading">{{ $t('add') }}</el-button>
                    <el-button size="mini" v-if="isAuth('sys:role:delete')" type="danger" @click="deleteHandle()" :disabled="dataListSelections.length <= 0 || dataListLoading">{{ $t('deleteBatch') }}</el-button>
                </el-button-group>
            </div>
        </div>
        <el-table :data="dataList" border stripe v-loading="dataListLoading" @selection-change="selectionChangeHandle" style="width: 100%;">
            <el-table-column type="selection" header-align="center" align="center" width="50">
            </el-table-column>
            <el-table-column prop="roleName" header-align="center" align="center" width="150" :label="$t('rules.ruleName')">
            </el-table-column>
            <el-table-column prop="type" header-align="center" align="center" width="100" :label="$t('rules.ruleType')">
                <template slot-scope="scope">
                    <el-tag size="mini" type="success" v-if="scope.row.type == 1">{{ $t('rules.ruleAdmin') }}</el-tag>
                    <el-tag size="mini" type="info" v-if="scope.row.type == 2">{{ $t('rules.ruleDept') }}</el-tag>
                    <el-tag size="mini" type="info" v-if="scope.row.type == 3">{{ $t('rules.ruleMall') }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="remark" header-align="center" align="center" :label="$t('rules.remark')">
            </el-table-column>
            <el-table-column fixed="right" header-align="center" align="center" width="150" :label="$t('handle')">
                <template slot-scope="scope">
                    <el-button v-if="isAuth('sys:role:update')" type="text" class="update-button" size="small" @click="addOrUpdateHandle(scope.row.roleId)">{{ $t('update') }}</el-button>
                    <el-button v-if="isAuth('sys:role:delete')" type="text" class="del-button" size="small" @click="deleteHandle(scope.row)">{{ $t('delete') }}</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalCount" layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
        <!-- 弹窗, 新增 / 修改 -->
        <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    </div>
</template>

<script>
import AddOrUpdate from "./add-or-update";
import { getList, deleteRole } from '@/api/sys/role'
export default {
    components: {
        AddOrUpdate
    },
    data() {
        return {
            dataForm: {
                roleName: ""
            },
            dataList: [],
            pageIndex: 1,
            pageSize: 10,
            totalCount: 0,
            dataListLoading: false,
            dataListSelections: [],
            addOrUpdateVisible: false
        };
    },

    activated() {
        this.dataForm.roleName = this.$route.query.key;
        let pageIndex = this.$route.query.pageIndex;

        if (pageIndex) {
            this.pageIndex = parseInt(pageIndex);
        }

        let pageSize = this.$route.query.pageSize;
        if (pageSize) {
            this.pageSize = parseInt(pageSize);
        }
        //this.getDataType()
        this.getDataList();
    },
    computed:{

    },
    methods: {
        thisPage(row){
          let totalPage = Math.ceil((this.totalCount-row)/this.pageSize)
          let pageIndex = this.pageIndex > totalPage ? totalPage : this.pageIndex
          //pageIndex = this.currentPage < 1 ? 1 : pageIndex
          return pageIndex
        },
        search() {
            this.pageIndex = 1;
            this.getDataList(); 
        },

        // 获取数据列表
        getDataList() {
            this.dataListLoading = true;

            getList({
                page: this.pageIndex,
                limit: this.pageSize,
                roleName: this.dataForm.roleName
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    this.dataList = data.data.list
                    this.totalCount = data.data.totalCount

                    if (data.data.page != this.pageIndex) {
                        this.pageIndex = data.data.page
                    }

                } else {
                    this.dataList = []
                    this.totalCount = 0
                    this.pageIndex = 1
                }

            }).finally(() => {
                this.dataListLoading = false;
            });
        },
        // 每页数
        sizeChangeHandle(val) {
            this.pageSize = val;
            this.pageIndex = 1;
            this.getDataList(); 
        },
        // 当前页
        currentChangeHandle(val) {
            this.pageIndex = val;
            this.getDataList(); 
        },
        // 多选
        selectionChangeHandle(val) {
            this.dataListSelections = val;
        },
        // 新增 / 修改
        addOrUpdateHandle(id) {
            this.addOrUpdateVisible = true;
            this.$nextTick(() => {
                this.$refs.addOrUpdate.init(id);
            });
            //  if(id) {
            //   this.$router.push({name:'sys_role_update',query: {id:id}});
            // } else {
            //   this.$router.push({name:'sys_role_add'});
            // }
        },
        // 删除
        deleteHandle(row) {
            var ids = row ? [row.roleId] :
                this.dataListSelections.map(item => {
                    return item.roleId;
                });
            var names = row ? [row.roleName] :
                this.dataListSelections.map(item => {
                    return item.roleName;
                });
            this.$confirm(
                    this.$t('rules.deleteRuleInfo'),
                    this.$t('prompt.title'), {
                        confirmButtonText: this.$t('confirm'),
                        cancelButtonText: this.$t('cancel'),
                        type: "warning"
                    }
                )
                .then(() => {

                    deleteRole(ids).then(({ data }) => {
                        if (data && data.code === 0) {
                          debugger
                            this.$message({
                                message: this.$t('prompt.success'),
                                type: "success",
                                duration: 1000
                            })
                            this.pageIndex = this.thisPage(ids.length)
                            this.getDataList();
                        } else {
                            this.$message.error(data.msg);
                        }
                    });
                })
                .catch(() => {});
        }
    }
};
</script>
