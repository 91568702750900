<template>
    <el-dialog :title="!dataForm.id ? this.$t('rules.addRuleInfo') : this.$t('rules.updateRuleInfo')" :close-on-click-modal="false" :modal-append-to-body="false" :lock-scroll="false" :visible.sync="visible">
        <!-- <div class="mod-config"> -->
        <el-form :model="dataForm" :rules="dataRule" ref="dataForm" v-loading="loading" label-width="80px">
            <el-form-item :label="$t('rules.ruleName')" prop="roleName">
                <el-input v-model.trim="dataForm.roleName" :placeholder="$t('rules.ruleName')" maxlength="30"></el-input>
              <span style="position: absolute; right: 10px; bottom: 0;">{{ dataForm.roleName ? dataForm.roleName.length : 0 }}/30</span>
            </el-form-item>
            <el-form-item :label="$t('rules.ruleType')" prop="type">
                <el-radio v-model="dataForm.type" :label="1">{{ $t('rules.ruleAdmin') }}</el-radio>
                <el-radio v-model="dataForm.type" :label="2">{{ $t('rules.ruleDept') }}</el-radio>
                <el-radio v-model="dataForm.type" :label="3">{{ $t('rules.ruleMall') }}</el-radio>
            </el-form-item>
            <el-form-item :label="$t('rules.remark')" prop="remark">
                <el-input v-model="dataForm.remark" :placeholder="$t('rules.remark')" maxlength="50"></el-input>
              <span style="position: absolute; right: 10px; bottom: 0;">{{ dataForm.remark ? dataForm.remark.length : 0 }}/50</span>
            </el-form-item>
            <el-form-item size="mini" :label="$t('rules.auths')">
                <div style="max-height:300px;overflow-y:auto;">
                    <el-tree :data="menuList" :props="menuListTreeProps" node-key="menuId" ref="menuListTree" :default-expand-all="false" show-checkbox>
                    </el-tree>
                </div>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
            <el-button :disabled="loading" type="primary" @click="dataFormSubmit()">{{ $t('confirm') }}</el-button>
        </span>
        <!--   </div> -->
    </el-dialog>
</template>

<script>
import { treeDataTranslate } from "@/utils";
import { getById, saveOrUpdate } from '@/api/sys/role'
import { getList } from '@/api/sys/menu'
export default {
    data() {
        return {
            visible: false,
            loading: false,
            menuList: [],
            menuListTreeProps: {
                label: "name",
                children: "children"
            },
            dataForm: {
                id: 0,
                roleName: "",
                remark: "",
                type: 1
            },
            dataRule: {
                roleName: [
                    { required: true, message: this.$t('validate.required'), trigger: "blur" }
                ],
                type: [
                    { required: true, message: this.$t('validate.required'), trigger: "blur" }
                ]
            },
            tempKey: "-666666" // 临时key, 用于解决tree半选中状态项不能传给后台接口问题. # 待优化
        };
    },
    methods: {
        init(id) {
            this.dataForm.id = id || 0;
            this.visible = true;

            getList().then(({ data }) => {
                    this.menuList = treeDataTranslate(data.data, "menuId");
                })
                .then(() => {
                    this.$nextTick(() => {
                        this.$refs["dataForm"].resetFields();
                        this.$refs.menuListTree.setCheckedKeys([]);
                    });
                })
                .then(() => {
                    if (this.dataForm.id) {

                        getById(this.dataForm.id).then(({ data }) => {
                            if (data && data.code === 0) {
                                this.dataForm.roleName = data.data.roleName;
                                this.dataForm.remark = data.data.remark;
                                this.dataForm.type = data.data.type

                                let menus = data.data.menuIdList;
                                var idx = menus.indexOf(this.tempKey);
                                if (idx !== -1) {
                                    menus.splice(idx, menus.length - idx);
                                }
                                this.$refs.menuListTree.setCheckedKeys(menus);
                            }
                        });
                    }
                });
        },
        // 表单提交
        dataFormSubmit() {
            this.$refs["dataForm"].validate(valid => {
                if (valid) {
                    this.loading = true;

                    saveOrUpdate({
                            roleId: this.dataForm.id || undefined,
                            roleName: this.dataForm.roleName,
                            remark: this.dataForm.remark,
                            type: this.dataForm.type,
                            menuIdList: [].concat(
                                this.$refs.menuListTree.getCheckedKeys(),
                                [this.tempKey],
                                this.$refs.menuListTree.getHalfCheckedKeys()
                            )
                        }).then(({ data }) => {
                            if (data && data.code === 0) {
                                this.$message({
                                    message: this.$t('prompt.success'),
                                    type: "success"
                                });

                                this.visible = false;
                                this.$emit("refreshDataList");
                                //this.$router.push({ name: "sys_role" });
                            } else {
                                this.$message.error(data.msg);
                            }
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            });
        }
    }
};
</script>
