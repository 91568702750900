import http from '@/utils/httpRequest'

export function getMenuTree(params) {
  return http({
          url: http.adornUrl('/sys/menu/getMenuTree'),
          method: 'get',
          params: params
        })
} 

export function getListByPid(params) {
  return http({
          url: http.adornUrl('/sys/menu/getListByPid'),
          method: 'get',
          params: params
        })
} 


export function getList(params) {
  return http({
          url: http.adornUrl('/sys/menu/list'),
          method: 'get',
          params: params
        })
} 

export function selectList(maxLevel = 2) {
  return  http({
        url: http.adornUrl("/sys/menu/select"),
        method: "get",
        params: http.adornParams({
          maxLevel: maxLevel
        })
      })
}

export function deleteMenu(id) {
	return http({
            url: http.adornUrl(`/sys/menu/delete/${id}`),
            method: "post" 
          }) 
}

export function getById(id) {
	return http({
        url: http.adornUrl(`/sys/menu/info/${id}`),
        method: "get"
        })
}
 
export function saveOrUpdate(data) {
	return http({
            url: http.adornUrl(
              `/sys/menu/${!data.menuId ? "save" : "update"}`
            ),
            method: "post",
            data: data
        })
}